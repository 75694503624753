<template>
  <div style="width: 100%">
    <div style="width: 100%">
      <div class="goodsDetail_header_inner">
        <div style="font-size: 18px; width: 80%; margin: 0 auto; color: #515a6e">
          <i class="el-icon-s-home"></i>{{ $t("Home") }} >{{ $t("Series Products") }}
        </div>
      </div>
      <ul class="disQueryUl">
        <li class="title" style="margin-right: 15px;">{{ $t("Series Products") }}:</li>
        <li v-for="(item, index) in List" :key="index" @click="toGoods(item)">
          <div v-if="item.id === ipId" class="hoverCls">{{ item.name }}</div>
          <div v-else class="disli">{{ item.name }}</div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { getIpType } from "@/api/goods/goods.js";

export default {
  data() {
    return {
      List: [],
      ipId: 0,

    };
  },
  // watch:{
  // 	"ipId"(){
  // 		//console.log(this.ipId)
  // 		this.toGoods(this.ipId)
  // 	}
  // },
  created() {
    this.getQueryValue();
    this.getData();
    this.ipId = this.$parent.QUERY_ipId //高亮菜单栏
  },
  methods: {
    getData() {
      getIpType().then((res) => {
        let resDataList=res.data.data;
        for (let i=0; i<resDataList.length; i++) {
          if (resDataList[i].sta === 0){
            resDataList.splice(i,1);
          }
        }
        this.List=this.filterData(resDataList);
      });
    },
    filterData(getData) {
      for (let key in getData) {
        if (this.$i18n.locale !== "zh-CN") {
          getData[key].name=getData[key].name_en;
        }
      }
      return getData;
    },
    toGoods(val) {
      this.$emit("selDis", val);
      this.ipId=parseInt(val.id);
    },
    //获取传参数进来的值
    getQueryValue() {
      var query=this.$route.query;
      this.ipId=query.id;
    },
  },
};
</script>

<style>
.disQueryUl {
  display: flex;
  /* border: 1px solid #238DA5; */
  width: 80%;
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 20px;
  /* height: 30px; */
  line-height: 30px;
}

.disQueryUl .title {
  font-weight: bold;
  font-size: 18px;
}

.disQueryUl li {
  font-size: 14px;
}

.disQueryUl .disli {
  text-align: center;
  color: #515a6e;
  font-size: 18px;
  margin-right: 18px;
  padding: 5px;
}

.disQueryUl .disli:hover {
  color: white;
  background: #238da5;
  cursor: pointer;
}

/* 高亮显示 */
.disQueryUl .hoverCls {
  text-align: center;
  font-size: 20px;
  margin-right: 25px;
  color: white;
  background: #238da5;
  cursor: pointer;
  padding: 5px;
}
</style>
