<template>
  <div>
      <Content @initCart="initCart" />
  </div>
</template>

<script>
import Content from "@/views/web/ipSeries/content/index.vue";
// import Content from "@/views/web/ipSeries/content/indexDisplay";
export default {
  components: {
    Content,
  },
  data: function () {
    return {};
  },
  methods: {
    //初始化
    initCart() {
      this.$emit("initCart");
    },
  },
};
</script>
